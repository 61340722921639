import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxDynamicForm, DataCacheService } from 'ng-ui';
import {
  CtxValidators,
  ResourceCreateFormData,
  ResourceUpdateFormData,
  copyToClipboard,
} from 'utils';
import { SamlSsoFormComponent } from '../saml-sso-form/saml-sso-form.component';
import { CtxButtonComponent } from 'ng-ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-oidc-form',
  templateUrl: './oidc-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    CtxButtonComponent,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class OidcFormComponent extends CtxDynamicForm implements OnInit {
  constructor(
    fb: FormBuilder,
    public dataCacheService: DataCacheService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<SamlSsoFormComponent>
  ) {
    super(fb);
  }

  /** Account details from DataCache */
  accountDetails = this.dataCacheService.getCachedValues('account');
  roles = this.dataCacheService.getCachedValues('role');
  roleMappingsFields: FormArray;
  _createNewOidcRoleMapping() {
    return this.fb.group({
      identityProviderRole: '',
      serviceProviderRole: '',
    });
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        clientId: [
          this.getInitialControlValue('clientId', this.dialogData),
          [Validators.maxLength(2048), Validators.required],
        ],
        additionalClientIds: [
          this.getInitialArrayControlValue(
            'additionalClientIds',
            this.dialogData
          ) || [],
          Validators.maxLength(1024),
        ],
        issuerUrl: [
          this.getInitialControlValue('issuerUrl', this.dialogData),
          [Validators.required, Validators.maxLength(2048)],
        ],
        defaultRole: [
          this.getInitialControlValue('defaultRole', this.dialogData),
          [Validators.required, Validators.maxLength(256)],
        ],
        claimMapping: this.fb.group({
          email: [
            this.getInitialControlValue('claimMapping.email', this.dialogData)
              ?.email || [],
            Validators.maxLength(1024),
          ],
          name: [
            this.getInitialControlValue('claimMapping.name', this.dialogData) ||
              [],
            Validators.maxLength(1024),
          ],
          role: [
            this.getInitialControlValue('claimMapping.role', this.dialogData) ||
              [],
            [Validators.maxLength(1024)],
          ],
        }),

        autoProvisionUsers: [
          this.getInitialControlValue('autoProvisionUsers', this.dialogData) ||
            false,
        ],
        enabled: [
          this.getInitialControlValue('enabled', this.dialogData, true),
        ],
        roleMappings: this.fb.array(
          this.getInitialFormArrayValue('roleMappings', this.dialogData) || [],
          [CtxValidators.uniqueKeys('identityProviderRole')]
        ),
      },
      { validators: [CtxValidators.oidc()] }
    );

    this.roleMappingsFields = this.formGroup.get('roleMappings') as FormArray;
  }
  copyToClipboard = copyToClipboard;

  get formValue() {
    const formValue = Object.assign({}, this.formGroup.value);
    formValue.additionalClientIds = this.csvToArray(
      this.formGroup.value.additionalClientIds
    );
    formValue.claimMapping.role = this.csvToArray(
      this.formGroup.value.claimMapping.role
    );
    formValue.claimMapping.email = this.csvToArray(
      this.formGroup.value.claimMapping.email
    );
    formValue.claimMapping.name = this.csvToArray(
      this.formGroup.value.claimMapping.name
    );
    return formValue;
  }

  createRoleMapping(checked: boolean) {
    if (checked) {
      if (this.roleMappingsFields.length === 0) {
        this.addFormArrayField(
          this.roleMappingsFields,
          this._createNewOidcRoleMapping()
        );
      }
    }
  }
}
